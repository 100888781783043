import React from "react";

import { TopBar } from "../../components/TopBar";
import { BottomBar } from "../../components/BottomBar";

import styles from "./privacy.module.css";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.privacy}>
      <TopBar simple={true} />
      <h2>{t("Privacy.Text1")}</h2>

      <p>{t("Privacy.Text2")}</p>

      <h3>{t("Privacy.Text3")}</h3>

      <p>{t("Privacy.Text4")}</p>

      <p>{t("Privacy.Text5")}</p>

      <p>{t("Privacy.Text6")}</p>

      <h3>{t("Privacy.Text7")}</h3>

      <p
        dangerouslySetInnerHTML={{
          __html: t("Privacy.Text8", {
            interpolation: { escapeValue: false },
          }),
        }}
      ></p>

      <p>{t("Privacy.Text9")}</p>

      <p>{t("Privacy.Text10")}</p>

      <ul>
        <li>{t("Privacy.Text11")}</li>
        <li>{t("Privacy.Text12")}</li>
        <li>{t("Privacy.Text13")}</li>
      </ul>

      <h3>{t("Privacy.Text14")}</h3>

      <p>{t("Privacy.Text15")}</p>

      <p
        dangerouslySetInnerHTML={{
          __html: t("Privacy.Text16", {
            interpolation: { escapeValue: false },
          }),
        }}
      ></p>

      <h3>{t("Privacy.Text17")}</h3>

      <p>{t("Privacy.Text18")}</p>

      <p>{t("Privacy.Text19")}</p>

      <p>{t("Privacy.Text20")}</p>

      <h3>{t("Privacy.Text21")}</h3>

      <p>{t("Privacy.Text22")}</p>

      <h3>{t("Privacy.Text23")}</h3>

      <p>{t("Privacy.Text24")}</p>

      <h3>{t("Privacy.Text25")}</h3>

      <p>{t("Privacy.Text26")}</p>

      <h3>{t("Privacy.Text27")}</h3>

      <p>{t("Privacy.Text28")}</p>

      <p>{t("Privacy.Text29")}</p>

      <BottomBar />
    </div>
  );
};

export default Privacy;
