import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./register-questions.module.css";

interface RegisterQuestionsProps {
  nextStep?: any;
  saveQuestions?: any;
}

export const RegisterQuestions = ({
  nextStep,
  saveQuestions,
}: RegisterQuestionsProps) => {
  const [how, setHow] = useState("");
  const [why, setWhy] = useState("");
  const [would, setWould] = useState("");

  const { t } = useTranslation();

  return (
    <div className={styles.registerQuestions}>
      <div className={styles.registerQuestionsImage}>
        <img src="/images/bg-register-form.png" alt="" />
      </div>
      <div className={styles.registerQuestionsForm}>
        <h3>{t("Register.Title1")}</h3>
        <p>{t("Register.Description1")}</p>
        <label>{t("Register.Question1")}</label>
        <input
          type="text"
          value={how}
          onChange={(e) => setHow(e.target.value)}
        />
        <label>{t("Register.Question2")}</label>
        <input
          type="text"
          value={why}
          onChange={(e) => setWhy(e.target.value)}
        />
        <label>{t("Register.Question3")}</label>
        <input
          type="text"
          value={would}
          onChange={(e) => setWould(e.target.value)}
        />
        <input
          type="button"
          className={styles.registerQuestionsBtn}
          value={t("Register.Next") as string}
          onClick={() => {
            saveQuestions({
              how,
              why,
              would,
            });
            nextStep();
          }}
        />
      </div>
    </div>
  );
};
