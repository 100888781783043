import React from "react";
import { render } from "react-snapshot";
import { I18nextProvider } from "react-i18next";

import App from "./App";
import i18n from "./i18n";

import "./index.css";

render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
