import React from "react";
import classnames from "classnames";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

import styles from "./pricing.module.css";

interface PricingProps {
  register: any;
}

export const Pricing = ({ register }: PricingProps) => {
  const { t } = useTranslation();
  return (
    <Element name="pricing">
      <li className={classnames("section", styles.sectionPricing)}>
        <div className={classnames("content", styles.sectionPricingContent)}>
          <ul className={styles.sectionPricingPlans}>
            <li className={styles.sectionPricingBasic}>
              <h3
                className={classnames(
                  styles.sectionPricingName,
                  styles.sectionPricingNameBasic
                )}
              >
                {t("Pricing.Basic.Name")}
              </h3>
              <div
                className={classnames(
                  styles.sectionPricingPrice,
                  styles.sectionPricingPriceBasic
                )}
              >
                {t("Pricing.Basic.Price")}
                <div className={styles.sectionPricingPricePeriod}>
                  /{t("Pricing.Basic.Period")}
                </div>
              </div>
              <ul className={styles.sectionPricingDetails}>
                <li>{t("Pricing.Basic.Feature_1")}</li>
                <li>{t("Pricing.Basic.Feature_2")}</li>
                <li>{t("Pricing.Basic.Feature_3")}</li>
                <li>{t("Pricing.Basic.Feature_4")}</li>
                <li>{t("Pricing.Standard.Feature_5")}</li>
                <li>{t("Pricing.Basic.Feature_5")}</li>
                <li>{t("Pricing.Basic.Feature_6")}</li>
                <li>{t("Pricing.Basic.Feature_7")}</li>
                <li>{t("Pricing.Standard.Feature_7")}</li>
                <li>{t("Pricing.Basic.Feature_8")}</li>
              </ul>
              <button
                className={styles.sectionPricingStartButton}
                onClick={() => register()}
              >
                {t("Pricing.StartNow")}
              </button>
            </li>
            <li className={styles.sectionPricingStandard}>
              <h3
                className={classnames(
                  styles.sectionPricingName,
                  styles.sectionPricingNameStandard
                )}
              >
                {t("Pricing.Standard.Name")}
              </h3>
              <div
                className={classnames(
                  styles.sectionPricingPrice,
                  styles.sectionPricingPriceStandard
                )}
              >
                {t("Pricing.Standard.Price")}
                <div className={styles.sectionPricingPricePeriod}>
                  /{t("Pricing.Standard.Period")}
                </div>
              </div>
              <ul className={styles.sectionPricingDetails}>
                <li>{t("Pricing.Standard.Feature_1")}</li>
                <li>{t("Pricing.Standard.Feature_2")}</li>
                <li>{t("Pricing.Standard.Feature_3")}</li>
                <li>{t("Pricing.Standard.Feature_4")}</li>
                <li>{t("Pricing.Standard.Feature_6")}</li>
                <li>{t("Pricing.Standard.Feature_8")}</li>
                <li>{t("Pricing.Standard.Feature_9")}</li>
                <li>{t("Pricing.Standard.Feature_10")}</li>
                <li>{t("Pricing.Standard.Feature_11")}</li>
                <li>{t("Pricing.Standard.Feature_12")}</li>
              </ul>
              <button
                className={styles.sectionPricingStartButton}
                onClick={() => register()}
              >
                {t("Pricing.StartNow")}
              </button>
            </li>
            <li className={styles.sectionPricingPremium}>
              <h3
                className={classnames(
                  styles.sectionPricingName,
                  styles.sectionPricingNamePremium
                )}
              >
                {t("Pricing.Premium.Name")}
              </h3>
              <div
                className={classnames(
                  styles.sectionPricingPrice,
                  styles.sectionPricingPricePremium
                )}
              >
                {t("Pricing.Premium.Price")}
                <div className={styles.sectionPricingPricePeriod}>
                  /{t("Pricing.Premium.Period")}
                </div>
              </div>
              <ul className={styles.sectionPricingDetails}>
                <li>{t("Pricing.Premium.Feature_1")}</li>
                <li>{t("Pricing.Premium.Feature_2")}</li>
                <li>{t("Pricing.Premium.Feature_3")}</li>
                <li>{t("Pricing.Premium.Feature_4")}</li>
                <li>{t("Pricing.Premium.Feature_5")}</li>
                <li>{t("Pricing.Premium.Feature_6")}</li>
                <li>{t("Pricing.Premium.Feature_7")}</li>
                <li>{t("Pricing.Premium.Feature_8")}</li>
                <li>{t("Pricing.Premium.Feature_9")}</li>
                <li>{t("Pricing.Premium.Feature_10")}</li>
              </ul>
              <button className={styles.sectionPricingStartButton}>
                {t("Pricing.Soon")}
              </button>
            </li>
          </ul>
          <p className={styles.sectionPricingExplanation}>
            {t("Pricing.Explanation1")} {t("Pricing.Explanation2")}
            {t("Pricing.Explanation3")}
          </p>
        </div>
      </li>
    </Element>
  );
};
