import React from "react";
import classnames from "classnames";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

import styles from "./about-us.module.css";

const PRESENTATION_LINK: { [key: string]: string } = {
  es: "https://bit.ly/2X96PDG",
  en: "https://bit.ly/3greOE5",
};

export const AboutUs = () => {
  const { t, i18n } = useTranslation();

  const link: string =
    PRESENTATION_LINK[i18n.language.toLowerCase()] || PRESENTATION_LINK.en;
  return (
    <Element name="about-us">
      <li className={classnames("section", styles.sectionAboutUs)}>
        <div className={classnames("content", styles.sectionAboutUsContent)}>
          <h2>{t("AboutUs.Title")}</h2>
          <ul>
            <li>
              <img src="/images/about-us-digitalization.svg" alt="..." />
              <h3>{t("AboutUs.Digital")}</h3>
              <p>{t("AboutUs.DigitalDescription")}</p>
            </li>
            <li>
              <img src="/images/about-us-arrow.svg" alt="..." />
            </li>
            <li>
              <img src="/images/about-us-tools.svg" alt="..." />
              <h3>{t("AboutUs.Tools")}</h3>
              <p>{t("AboutUs.ToolsDescription")}</p>
            </li>
            <li>
              <img src="/images/about-us-arrow.svg" alt="..." />
            </li>
            <li>
              <img src="/images/about-us-revenue.svg" alt="..." />
              <h3>{t("AboutUs.Revenue")}</h3>
              <p>{t("AboutUs.RevenueDescription")}</p>
            </li>
          </ul>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-secondary-action"
            title={t("AboutUs.TELL_ME_MORE")}
          >
            {t("AboutUs.TELL_ME_MORE")}
          </a>
        </div>
      </li>
    </Element>
  );
};
