import React, { useState } from "react";
import StepWizard from "react-step-wizard";

import { RegisterForm } from "../RegisterForm";
import { RegisterQuestions } from "../RegisterQuestions";

export const Register = () => {
  const [questions, setQuestions] = useState<any>({});
  return (
    <StepWizard>
      <RegisterQuestions saveQuestions={setQuestions} />
      <RegisterForm questions={questions} />
    </StepWizard>
  );
};
