import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./bottom-bar.module.css";

export const BottomBar = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.bottomBar}>
      {t("Bottom.PoweredBy")} -{" "}
      <Link to="/termsandconditions" title={t("Bottom.TermsAndConditions")}>
        {t("Bottom.TermsAndConditions")}
      </Link>{" "}
      -{" "}
      <Link to="/privacy" title={t("Bottom.PrivacyPolicy")}>
        {t("Bottom.PrivacyPolicy")}
      </Link>
    </div>
  );
};
