import React from "react";
import { Element, scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import styles from "./demo.module.css";

export const Demo = () => {
  const { t } = useTranslation();

  const scrollTo = (elementId: string) => () => {
    scroller.scrollTo(elementId, {
      duration: 700,
      delay: 50,
      smooth: true,
    });
  };

  return (
    <Element name="demo">
      <li className={classnames("section", styles.sectionDemo)}>
        <div className={classnames("content", styles.sectionDemoContent)}>
          <h2 className={styles.sectionDemoTitle}>{t("Demo.Title")}</h2>
          <button
            className={styles.sectionDemoButton}
            onClick={scrollTo("contact-us")}
          >
            {t("Demo.VER_UNA_DEMO")}
          </button>
        </div>
      </li>
    </Element>
  );
};
