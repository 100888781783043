import React, { useState } from "react";
import Popup from "reactjs-popup";

import { TopBar } from "../../components/TopBar";
import { BottomBar } from "../../components/BottomBar";
import { AboutUs } from "../../components/AbousUs";
import { Welcome } from "../../components/Welcome";
import { Features } from "../../components/Features";
import { Products } from "../../components/Products";
import { Demo } from "../../components/Demo";
import { Pricing } from "../../components/Pricing";
import { ContactUs } from "../../components/ContactUs";
import { Register } from "../../components/Register";

const Home = () => {
  const [registerPopup, setRegisterPopup] = useState(false);
  const onCloseModal = () => setRegisterPopup(false);
  const openRegister = () => setRegisterPopup(true);

  return (
    <>
      <TopBar />
      <ul className="main-scroller" id="sections">
        <Welcome />
        <AboutUs />
        <Features />
        <Products register={openRegister} />
        <Demo />
        <Pricing register={openRegister} />
        <ContactUs />
      </ul>
      <Popup open={registerPopup} onClose={onCloseModal}>
        <Register />
      </Popup>
      <BottomBar />
    </>
  );
};

export default Home;
