import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Home from "./pages/Home";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

const App = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <Helmet>
        <title>{t("MetaTags.Title")}</title>
        <meta name="description" content={t("MetaTags.Description")} />
        <meta name="twitter:title" content={t("MetaTags.TwitterTitle")} />
        <meta
          name="twitter:description"
          content={t("MetaTags.TwitterDescription")}
        />
        <meta property="og:title" content={t("MetaTags.OGTitle")} />
        <meta property="og:description" content={t("MetaTags.OGDescription")} />
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText={t("CookieConsent.ButtonAccept")}
        cookieName="clientApp"
        containerClasses="cookie-consent-container"
        buttonClasses="cookie-consent-button"
        expires={150}
      >
        {t("CookieConsent.Description")} {t("CookieConsent.TermsAndConditions")}
        <a href="klivr.club/termsandconditions" style={{ fontSize: "10px" }}>
          {t("CookieConsent.Here")}
        </a>
      </CookieConsent>
      <Switch>
        <Route path="/termsandconditions">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
