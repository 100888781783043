import React from "react";
import { Element } from "react-scroll";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./welcome.module.css";

export const Welcome = () => {
  const { t } = useTranslation();

  return (
    <Element name="welcome">
      <li className={classnames("section", styles.sectionMain)}>
        <div className={classnames("content", styles.sectionMainContent)}>
          <h1>
            <span className={styles.sectionMainTitle}>{t("Welcome.H1_1")}</span>
            {t("Welcome.H1_2")}
          </h1>
          <p>{t("Welcome.Description_1")}</p>
          <p>{t("Welcome.Description_2")}</p>

          <a
            href="https://bit.ly/39I46qc"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-secondary-action"
            title={t("Welcome.TRY_IT")}
          >
            {t("Welcome.TRY_IT")}
          </a>

          <img src="/images/main-section-gadget.png" alt="..." />
        </div>
      </li>
    </Element>
  );
};
