import React, { useState } from "react";
import classnames from "classnames";
import { scroller } from "react-scroll";

import styles from "./topbar.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface TopBarProps {
  simple?: boolean;
}

const LANG: { [key: string]: any } = {
  es: {
    image: "/images/english.jpg",
    to: "en",
  },
  en: {
    image: "/images/spanish.jpg",
    to: "es",
  },
};

const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);
const isMobile = vw <= 767;

export const TopBar = ({ simple }: TopBarProps) => {
  const { t, i18n } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const scrollTo = (elementId: string, offset = 0) => () => {
    scroller.scrollTo(elementId, {
      duration: 700,
      delay: 50,
      smooth: true,
      offset,
    });
    setMenuVisible(false);
  };

  const onChangeLanguage = () => {
    i18n.changeLanguage(LANG[i18n.language].to);
  };
  return (
    <nav className={styles.topBar}>
      <img
        src="/images/hamburger.svg"
        className={styles.topBarHamburger}
        onClick={() => setMenuVisible(!menuVisible)}
        alt="Menu"
      />
      <img
        className={styles.topBarLogo}
        src="/images/logo-klivr.png"
        alt="Klivr"
      />

      {simple ? (
        <ul
          className={classnames(
            styles.topBarSectionActions,
            styles.topBarSectionActionsLeft,
            menuVisible ? styles.topBarSectionActionsToggled : ""
          )}
        >
          <li className={styles.topBarSectionActionsLogo}>
            <Link to="/">
              <img src="/images/logo-klivr.png" alt="Klivr" />
            </Link>
          </li>
          <li className="btn" onClick={scrollTo("welcome")}>
            <Link to="/">{t("Menu.Main")}</Link>
          </li>
        </ul>
      ) : (
        <ul
          className={classnames(
            styles.topBarSectionActions,
            menuVisible ? styles.topBarSectionActionsToggled : ""
          )}
        >
          <li className={styles.topBarSectionActionsLogo}>
            <img src="/images/logo-klivr.png" alt="Klivr" />
          </li>
          <li className="btn" onClick={scrollTo("welcome")}>
            {t("Menu.Main")}
          </li>
          <li
            className="btn"
            onClick={scrollTo("about-us", isMobile ? -70 : -150)}
          >
            {t("Menu.AboutUs")}
          </li>
          <li className="btn" onClick={scrollTo("features", -60)}>
            {t("Menu.Features")}
          </li>
          <li
            className="btn"
            onClick={scrollTo("products", isMobile ? -50 : 0)}
          >
            {t("Menu.Tool")}
          </li>
          <li className="btn" onClick={scrollTo("demo", isMobile ? 0 : -200)}>
            {t("Menu.Demo")}
          </li>
          <li className="btn" onClick={scrollTo("contact-us")}>
            {t("Menu.ContactUs")}
          </li>
          <li>
            <a
              href="https://admin.bar.klivr.club"
              className="btn-primary-action"
              title={t("Menu.Login")}
            >
              {t("Menu.Login")}
            </a>
          </li>
        </ul>
      )}

      <ul className={styles.topBarSocialActions}>
        <li
          className={styles.topBarSocialActionsLang}
          onClick={onChangeLanguage}
        >
          <img src={LANG[i18n.language].image} alt="Spanish" />
        </li>
        <li>
          <img src="/images/facebook.svg" alt="F" />
        </li>
        <li>
          <img src="/images/instagram.svg" alt="I" />
        </li>
        <li>
          <img src="/images/linkedin.svg" alt="L" />
        </li>
      </ul>
    </nav>
  );
};
