import React from "react";
import { Element } from "react-scroll";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./features.module.css";

export const Features = () => {
  const { t } = useTranslation();
  return (
    <Element name="features">
      <li className={classnames("section", styles.sectionFeatures)}>
        <div className={classnames("content", styles.sectionFeaturesContent)}>
          <h2>{t("Features.Title")}</h2>
          <ul className={styles.sectionFeaturesList}>
            <li>
              <img
                src="/images/ft-menu.svg"
                alt={t("Features.Feature1.Title")}
              />
              <h3>{t("Features.Feature1.Title")}</h3>
              <p>{t("Features.Feature1.Description")}</p>
            </li>
            <li>
              <img
                src="/images/ft-promote.svg"
                alt={t("Features.Feature2.Title")}
              />
              <h3>{t("Features.Feature2.Title")}</h3>
              <p>{t("Features.Feature2.Description")}</p>
            </li>
            <li>
              <img
                src="/images/ft-engagement.svg"
                alt={t("Features.Feature3.Title")}
              />
              <h3>{t("Features.Feature3.Title")}</h3>
              <p>{t("Features.Feature3.Description")}</p>
            </li>
            <li>
              <img
                src="/images/ft-language.svg"
                alt={t("Features.Feature4.Title")}
              />
              <h3>{t("Features.Feature4.Title")}</h3>
              <p>{t("Features.Feature4.Description")}</p>
            </li>
            <li>
              <img
                src="/images/ft-eco-friendly.svg"
                alt={t("Features.Feature5.Title")}
              />
              <h3>{t("Features.Feature5.Title")}</h3>
              <p>{t("Features.Feature5.Description")}</p>
            </li>
            <li>
              <img
                src="/images/ft-better-than-app.svg"
                alt={t("Features.Feature6.Title")}
              />
              <h3>{t("Features.Feature6.Title")}</h3>
              <p>{t("Features.Feature6.Description")}</p>
            </li>
          </ul>
        </div>
      </li>
    </Element>
  );
};
