import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "./register-form.module.css";

interface RegisterFormProps {
  previousStep?: any;
  questions: any;
}

export const RegisterForm = ({
  previousStep,
  questions,
}: RegisterFormProps) => {
  const { t, i18n } = useTranslation();
  const [clientEstablishment, setClientEstablishment] = useState("");
  const [clientTotal, setClientTotal] = useState("");
  const [clientCountry, setClientCountry] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [clientPassword2, setClientPassword2] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState<any>(null);
  const [complete, setComplete] = useState(false);

  const onChange = (func: any) => (e: any) => {
    func(e.target.value);
  };

  const onChangeCaptcha = (value: any) => {
    setToken(value);
  };

  const register = async () => {
    if (
      !clientEstablishment.length ||
      !clientName ||
      !clientEmail ||
      !clientPassword ||
      !clientPassword2
    ) {
      setError(t("Register.Msgs.CompleteAll"));
      return;
    }

    if (clientPassword.length < 8) {
      setError(t("Register.Msgs.PasswordLength"));
      return;
    }

    if (clientPassword !== clientPassword2) {
      setError(t("Register.Msgs.PasswordMismatch"));
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/register`,
        {
          ...questions,
          total: clientTotal,
          country: clientCountry,
          city: clientCity,
          name: clientName,
          establishment: clientEstablishment,
          email: clientEmail,
          password: clientPassword,
          token,
          language: i18n.language.toLowerCase(),
        }
      );

      if (response) {
        setError("");
        setComplete(true);
      } else {
        setError(t("Register.Msgs.GeneralError"));
        setComplete(false);
      }
    } catch (err) {
      if (err.data && err.data.code) {
        if (err.data.code === "1") {
          setError(t("Register.Msgs.NameUsed"));
        }

        if (err.data.code === "2") {
          setError(t("Register.Msgs.GeneralError"));
        }

        if (err.data.code === "3") {
          setError(t("Register.Msgs.RegisteredEmail"));
        }

        if (err.data.code === "4") {
          setError(t("Register.Msgs.RegisteredUser"));
        }

        if (err.data.code === "6") {
          setError(t("Register.Msgs.CaptchaError"));
        }
      }
      setError(t("Contact.Msgs.CaptchaError"));
      setComplete(false);
    }
  };

  return (
    <div className={styles.registerForm}>
      <div className={styles.registerFormImage}>
        <img src="/images/bg-register-form-2.png" alt="" />
      </div>
      <div className={styles.registerFormForm}>
        <h3>{t("Register.Title2")}</h3>
        <p>{t("Register.Description2")}</p>
        <input
          type="text"
          value={clientEstablishment}
          onChange={onChange(setClientEstablishment)}
          placeholder={t("Register.ClientEstablishment")}
        />
        <input
          type="text"
          value={clientTotal}
          onChange={onChange(setClientTotal)}
          placeholder={t("Register.ClientTotal")}
        />
        <input
          type="text"
          value={clientCountry}
          onChange={onChange(setClientCountry)}
          placeholder={t("Register.ClientCountry")}
        />
        <input
          type="text"
          value={clientCity}
          onChange={onChange(setClientCity)}
          placeholder={t("Register.ClientCity")}
        />
        <input
          type="text"
          value={clientName}
          onChange={onChange(setClientName)}
          placeholder={t("Register.ClientName")}
        />
        <input
          type="text"
          value={clientEmail}
          onChange={onChange(setClientEmail)}
          placeholder={t("Register.ClientEmail")}
        />
        <input
          type="password"
          value={clientPassword}
          onChange={onChange(setClientPassword)}
          placeholder={t("Register.ClientPassword")}
        />
        <input
          type="password"
          value={clientPassword2}
          onChange={onChange(setClientPassword2)}
          placeholder={t("Register.ClientRepeatPassword")}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN as string}
          onChange={onChangeCaptcha}
        />

        {error.length ? <div className="errorMsg">{error}</div> : null}
        {complete ? (
          <div className="successMsg">{t("Register.Msgs.Registered")}</div>
        ) : null}

        <p className={styles.registerFormTerms}>{t("Register.Terms")}</p>
        <input
          type="button"
          className={styles.registerFormBtn}
          value={t("Register.Back") as string}
          onClick={previousStep}
        />
        <input
          type="button"
          className={styles.registerFormBtn}
          onClick={register}
          value={t("Register.Register") as string}
        />
      </div>
    </div>
  );
};
