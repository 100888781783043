import React from "react";
import classnames from "classnames";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

import styles from "./products.module.css";

interface ProductsProps {
  register: any;
}

export const Products = ({ register }: ProductsProps) => {
  const { t } = useTranslation();
  return (
    <Element name="products">
      <li className={classnames("section", styles.sectionProducts)}>
        <div className={classnames("section", styles.sectionProductsContent)}>
          <h2>Klivr BAR</h2>
          <div className={styles.sectionProductsContentList}>
            <img src="/images/bar-interaction.png" alt="..." />
            <ul>
              <li>
                <img src="/images/step-1.svg" alt="1" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("Products.Step1", {
                      txtOrange: '<span class="txt-orange">',
                      endTxtOrange: "</span>",
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></div>
              </li>
              <li>
                <img src="/images/step-2.svg" alt="2" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("Products.Step2", {
                      txtOrange: '<span class="txt-orange">',
                      endTxtOrange: "</span>",
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></div>
              </li>
              <li>
                <img src="/images/step-3.svg" alt="3" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("Products.Step3", {
                      txtOrange: '<span class="txt-orange">',
                      endTxtOrange: "</span>",
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></div>
              </li>
              <li>
                <button
                  className="btn-secondary-action"
                  onClick={() => register()}
                >
                  {t("Products.LO_QUIERO_GRATIS")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </Element>
  );
};
