import React from "react";

import { TopBar } from "../../components/TopBar";
import { BottomBar } from "../../components/BottomBar";

import styles from "./terms.module.css";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.terms}>
      <TopBar simple={true} />
      <h1>{t("Terms.Text1")}</h1>
      <h2>{t("Terms.Text2")}</h2>
      <p>{t("Terms.Text3")}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: t("Terms.Text4", {
            interpolation: { escapeValue: false },
          }),
        }}
      ></p>
      <p>{t("Terms.Text5")}</p>
      <h2>{t("Terms.Text6")}</h2>
      <p>{t("Terms.Text7")}</p>
      <p>{t("Terms.Text8")}</p>
      <h2>{t("Terms.Text9")}</h2>
      <p>{t("Terms.Text10")}</p>
      <ul>
        <li>{t("Terms.Text11")}</li>
        <li>{t("Terms.Text12")}</li>
        <li>{t("Terms.Text13")}</li>
        <li>{t("Terms.Text14")}</li>
        <li>{t("Terms.Text15")}</li>
        <li>{t("Terms.Text16")}</li>
        <li>{t("Terms.Text17")}</li>
        <li>{t("Terms.Text18")}</li>
      </ul>

      <p>{t("Terms.Text19")}</p>

      <h2>{t("Terms.Text20")}</h2>
      <p>{t("Terms.Text21")}</p>

      <h2>{t("Terms.Text22")}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: t("Terms.Text23", {
            interpolation: { escapeValue: false },
          }),
        }}
      ></p>
      <p>{t("Terms.Text24")}</p>
      <h2>{t("Terms.Text25")}</h2>
      <p>{t("Terms.Text26")}</p>
      <h2>{t("Terms.Text27")}</h2>

      <p>{t("Terms.Text28")}</p>

      <h2>{t("Terms.Text29")}</h2>

      <p>{t("Terms.Text30")}</p>

      <h2>{t("Terms.Text31")}</h2>

      <p>{t("Terms.Text32")}</p>

      <h2>{t("Terms.Text33")}</h2>

      <p>{t("Terms.Text34")}</p>

      <h2>{t("Terms.Text35")}</h2>

      <p>{t("Terms.Text36")}</p>

      <h2>{t("Terms.Text37")}</h2>

      <p>{t("Terms.Text38")}</p>

      <h2>{t("Terms.Text39")}</h2>

      <p>{t("Terms.Text40")}</p>

      <BottomBar />
    </div>
  );
};

export default Terms;
