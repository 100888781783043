import React, { useState } from "react";
import classnames from "classnames";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import axios from "axios";

import styles from "./contact-us.module.css";
import ReCAPTCHA from "react-google-recaptcha";

export const ContactUs = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState<any>(null);
  const [complete, setComplete] = useState(false);

  const onChangeCaptcha = (value: any) => {
    setToken(value);
  };

  const sendContact = async () => {
    if (
      !firstName.length ||
      !lastName.length ||
      !company.length ||
      !companyEmail.length ||
      !country.length
    ) {
      setError(t("Contact.Msgs.CompleteAll"));
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/contact`,
        {
          firstName,
          lastName,
          company,
          companyEmail,
          phoneNumber,
          country,
          token,
        }
      );

      if (response) {
        setError("");
        setComplete(true);
        setFirstName("");
        setLastName("");
        setCompany("");
        setCompanyEmail("");
        setPhoneNumber("");
        setCountry("");
      } else {
        setError(t("Contact.Msgs.GenericError"));
        setComplete(false);
      }
    } catch (err) {
      setError(t("Contact.Msgs.CaptchaError"));
      setComplete(false);
    }
  };

  return (
    <Element name="contact-us">
      <li className={classnames("section", styles.sectionContactUs)}>
        <div className={classnames("content", styles.sectionContactUsContent)}>
          <div className={styles.sectionContactUsColumn}>
            <h2>{t("Contact.Title")}</h2>
            <p>{t("Contact.Description")}</p>
            <p>{t("Contact.RequestDemo")}</p>
            <ul>
              <li>{t("Contact.Bullet1")}</li>
              <li>{t("Contact.Bullet2")}</li>
              <li>{t("Contact.Bullet3")}</li>
            </ul>
          </div>
          <div
            className={classnames(
              styles.sectionContactUsColumn,
              styles.sectionContactUsForm
            )}
          >
            <div className={styles.sectionContactUsFormField}>
              <label>*{t("Contact.FirstName")}</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={styles.sectionContactUsFormField}>
              <label>*{t("Contact.LastName")}</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className={styles.sectionContactUsFormField}>
              <label>*{t("Contact.Establishment")}</label>
              <input
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div className={styles.sectionContactUsFormField}>
              <label>*{t("Contact.BusinessEmail")}</label>
              <input
                type="text"
                value={companyEmail}
                onChange={(e) => setCompanyEmail(e.target.value)}
              />
            </div>
            <div className={styles.sectionContactUsFormField}>
              <label>{t("Contact.PhoneNumber")}</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className={styles.sectionContactUsFormField}>
              <label>*{t("Contact.Country")}</label>
              <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div
              className={classnames(
                styles.sectionContactUsFormField,
                styles.sectionContactUsFormFieldFull
              )}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN as string}
                onChange={onChangeCaptcha}
              />
            </div>
            <div
              className={classnames(
                styles.sectionContactUsFormField,
                styles.sectionContactUsFormFieldFull,
                styles.sectionContactUsFormFieldBtn
              )}
            >
              <button
                className={styles.sectionContactUsSendBtn}
                onClick={sendContact}
              >
                {t("Contact.SEND")}
              </button>
              <div
                className={classnames(
                  styles.sectionContactUsFormField,
                  styles.sectionContactUsFormFieldFull
                )}
              >
                * {t("Contact.MandatoryFields")}
              </div>
              {error.length ? <div className="errorMsg">{error}</div> : null}
              {complete ? (
                <div className="successMsg">{t("Contact.Msgs.Success")}</div>
              ) : null}
            </div>
          </div>
        </div>
      </li>
    </Element>
  );
};
